html.ios {
  --ion-default-font: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

html.md {
  --ion-default-font: "Roboto", "Helvetica Neue", sans-serif;
}

html {
  --ion-font-family: var(--ion-default-font);
}

body {
  background: var(--ion-background-color);
}

body.backdrop-no-scroll {
  overflow: hidden;
}

html.ios ion-modal.modal-card .ion-page > ion-header > ion-toolbar:first-of-type {
  padding-top: 0px;
}

html.ios ion-modal .ion-page {
  border-radius: inherit;
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary, #3880ff) !important;
  --ion-color-base-rgb: var(--ion-color-primary-rgb, 56, 128, 255) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-primary-shade, #3171e0) !important;
  --ion-color-tint: var(--ion-color-primary-tint, #4c8dff) !important;
}

.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary, #3dc2ff) !important;
  --ion-color-base-rgb: var(--ion-color-secondary-rgb, 61, 194, 255) !important;
  --ion-color-contrast: var(--ion-color-secondary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-secondary-shade, #36abe0) !important;
  --ion-color-tint: var(--ion-color-secondary-tint, #50c8ff) !important;
}

.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary, #5260ff) !important;
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb, 82, 96, 255) !important;
  --ion-color-contrast: var(--ion-color-tertiary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-tertiary-shade, #4854e0) !important;
  --ion-color-tint: var(--ion-color-tertiary-tint, #6370ff) !important;
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success, #2dd36f) !important;
  --ion-color-base-rgb: var(--ion-color-success-rgb, 45, 211, 111) !important;
  --ion-color-contrast: var(--ion-color-success-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-success-shade, #28ba62) !important;
  --ion-color-tint: var(--ion-color-success-tint, #42d77d) !important;
}

.ion-color-warning {
  --ion-color-base: var(--ion-color-warning, #ffc409) !important;
  --ion-color-base-rgb: var(--ion-color-warning-rgb, 255, 196, 9) !important;
  --ion-color-contrast: var(--ion-color-warning-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-warning-shade, #e0ac08) !important;
  --ion-color-tint: var(--ion-color-warning-tint, #ffca22) !important;
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger, #eb445a) !important;
  --ion-color-base-rgb: var(--ion-color-danger-rgb, 235, 68, 90) !important;
  --ion-color-contrast: var(--ion-color-danger-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-danger-shade, #cf3c4f) !important;
  --ion-color-tint: var(--ion-color-danger-tint, #ed576b) !important;
}

.ion-color-light {
  --ion-color-base: var(--ion-color-light, #f4f5f8) !important;
  --ion-color-base-rgb: var(--ion-color-light-rgb, 244, 245, 248) !important;
  --ion-color-contrast: var(--ion-color-light-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-light-shade, #d7d8da) !important;
  --ion-color-tint: var(--ion-color-light-tint, #f5f6f9) !important;
}

.ion-color-medium {
  --ion-color-base: var(--ion-color-medium, #92949c) !important;
  --ion-color-base-rgb: var(--ion-color-medium-rgb, 146, 148, 156) !important;
  --ion-color-contrast: var(--ion-color-medium-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-medium-shade, #808289) !important;
  --ion-color-tint: var(--ion-color-medium-tint, #9d9fa6) !important;
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark, #222428) !important;
  --ion-color-base-rgb: var(--ion-color-dark-rgb, 34, 36, 40) !important;
  --ion-color-contrast: var(--ion-color-dark-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-dark-shade, #1e2023) !important;
  --ion-color-tint: var(--ion-color-dark-tint, #383a3e) !important;
}

.ion-page {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  overflow: hidden;
  z-index: 0;
}

.split-pane-visible > .ion-page.split-pane-main {
  position: relative;
}

ion-route,
ion-route-redirect,
ion-router,
ion-select-option,
ion-nav-controller,
ion-menu-controller,
ion-action-sheet-controller,
ion-alert-controller,
ion-loading-controller,
ion-modal-controller,
ion-picker-controller,
ion-popover-controller,
ion-toast-controller,
.ion-page-hidden,
[hidden] {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

.ion-page-invisible {
  opacity: 0;
}

.can-go-back > ion-header ion-back-button {
  display: block;
}

html.plt-ios.plt-hybrid, html.plt-ios.plt-pwa {
  --ion-statusbar-padding: 20px;
}

@supports (padding-top: 20px) {
  html {
    --ion-safe-area-top: var(--ion-statusbar-padding);
  }
}
@supports (padding-top: constant(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: constant(safe-area-inset-top);
    --ion-safe-area-bottom: constant(safe-area-inset-bottom);
    --ion-safe-area-left: constant(safe-area-inset-left);
    --ion-safe-area-right: constant(safe-area-inset-right);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
  }
}
ion-card.ion-color .ion-inherit-color,
ion-card-header.ion-color .ion-inherit-color {
  color: inherit;
}

.menu-content {
  transform: translate3d(0,  0,  0);
}

.menu-content-open {
  cursor: pointer;
  touch-action: manipulation;
  pointer-events: none;
}

.ios .menu-content-reveal {
  box-shadow: -8px 0 42px rgba(0, 0, 0, 0.08);
}

[dir=rtl].ios .menu-content-reveal {
  box-shadow: 8px 0 42px rgba(0, 0, 0, 0.08);
}

.md .menu-content-reveal {
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.18);
}

.md .menu-content-push {
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.18);
}

/*# sourceMappingURL=core.css.map */
